import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

    @Input()
    size = 2;

    @Input()
    set objNames(obj: any){
        if(Array.isArray(obj)){
          obj = obj[0]
        }

        if(obj && (obj.firstName || obj.lastName)){
            this.name =  `${obj.firstName} ${obj.lastName}`;
        };
    }

    public name: any;


    constructor(
    ) {}

    ngOnInit() {
    }

    getFirstStrName() {
        if(this.name) {
          const fullName = this.name.trim().split(' ');
          const firstName = fullName[0];
          let lastName = '';
          if(fullName.length > 1) {
            lastName = fullName[fullName.length - 1];
          }

          return firstName.slice(0, 1).toUpperCase() + lastName.slice(0, 1).toUpperCase()
        }
      }

}
