import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {TableOrderComponent} from "./table-order.component";


@NgModule({
    imports: [
        CommonModule,
    ],
    providers: [
    ],
    declarations: [
        TableOrderComponent
    ],
    exports: [
        TableOrderComponent
    ]
})
export class TableOrderModule { }
