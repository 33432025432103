import {AccountsAdTypes} from "../../system/configurations";

export enum contaType{
    Facebook = 0,
    Content = 1,
    Google = 2,
    Egoi = 3
}

export const idTypes = {
    [contaType.Facebook]: AccountsAdTypes.facebook,
    [contaType.Content]: AccountsAdTypes.facebookContent,
    [contaType.Google]: AccountsAdTypes.google,
    [contaType.Egoi]: AccountsAdTypes.egoi,
};
