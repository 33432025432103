import { EventEmitter, Injectable } from '@angular/core';
import { Endpoints } from "../configurations";
import { SessionService } from "./session.service";
import { HttpClient } from "@angular/common/http";
import {
  bodyGetMetricsTypeAccountAd, paramsGetAccountsAd,
  paramsGetCampaigns, paramsGetGoals, paramsGetInfluencers,
  paramsGetInsights,
  paramsGetMetricsPeriod, paramsGetMetricsTypeAccountAd, paramsGetSummary
} from "../../marketing/models/dashboard";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  onPostAccountAd = new EventEmitter();
  onDeleteAccountAd = new EventEmitter();

  constructor(private sessionService: SessionService,
    private http: HttpClient) {
  }

  getMetrics(): any {
    const url = Endpoints.MARKETING.DASHBOARD_METRICS();
    const opt = this.sessionService.getOptions();

    return this.http.get(url, opt);
  }
  getMetricsMonth(): any {
    const url = Endpoints.MARKETING.DASHBOARD_METRICS_MONTH();
    const opt = this.sessionService.getOptions();

    return this.http.get(url, opt);
  }
  getMetricsLastMonth(): any {
    const url = Endpoints.MARKETING.DASHBOARD_METRICS_LAST_MONTH();
    const opt = this.sessionService.getOptions();

    return this.http.get(url, opt);
  }
  getMetricsPeriod(params: paramsGetMetricsPeriod): any {
    const url = Endpoints.MARKETING.DASHBOARD_METRICS_PERIOD;
    const opt = this.sessionService.getOptions();
    opt['params'] = params;

    return this.http.get(url, opt);
  }
  getMetricsTypeAccountAd(params: paramsGetMetricsTypeAccountAd, body: bodyGetMetricsTypeAccountAd): any {
    const url = Endpoints.MARKETING.DASHBOARD_METRICS_TYPE_ACCOUNT_AD;
    const opt = this.sessionService.getOptions();
    opt['params'] = params;

    return this.http.post(url, body, opt);
  }
  getPipeline(params): any {
    const url = Endpoints.MARKETING.DASHBOARD_PIPELINE;
    const opt = this.sessionService.getOptions();
    opt['params'] = params;

    return this.http.get(url, opt);
  }
  getCampaigns(params: paramsGetCampaigns): any {
    const url = Endpoints.MARKETING.DASHBOARD_CAMPAIGNS;
    const opt = this.sessionService.getOptions();
    opt['params'] = params;

    return this.http.get(url, opt);
  }
  getGoals(params: paramsGetGoals): any {
    const url = Endpoints.MARKETING.DASHBOARD_CAMPAIGNS_GOALS;
    const opt = this.sessionService.getOptions();
    opt['params'] = params;

    return this.http.get(url, opt);
  }
  getInsights(params: paramsGetInsights): any {
    const url = Endpoints.MARKETING.DASHBOARD_CAMPAIGNS_INSIGHTS;
    const opt = this.sessionService.getOptions();
    opt['params'] = params;

    return this.http.get(url, opt);
  }
  getSummary(params: paramsGetSummary): any {
    const url = Endpoints.MARKETING.DASHBOARD_CAMPAIGNS_SUMMARY;
    const opt = this.sessionService.getOptions();
    opt['params'] = params;

    return this.http.get(url, opt);
  }
  getInfluencers(params: paramsGetInfluencers): any {
    const url = Endpoints.MARKETING.DASHBOARD_INFLUENCER;
    const opt = this.sessionService.getOptions();
    opt['params'] = params;

    return this.http.get(url, opt);
  }

  getAccountsAd(params?: paramsGetAccountsAd): any {
    const url = Endpoints.MARKETING.ACCOUNTS_AD;
    const opt = this.sessionService.getOptions();
    opt['params'] = params;

    return this.http.get(url, opt);
  }
  getAccountAd(uuid: string): any {
    const url = Endpoints.MARKETING.ACCOUNTS_AD_PUT(uuid);
    const opt = this.sessionService.getOptions();

    return this.http.get(url, opt);
  }

  postAccountAd(body: any) {
    const url = Endpoints.MARKETING.ACCOUNTS_AD;
    const opt = this.sessionService.getOptions();

    return this.http.post(url, body, opt);
  }
  deleteAccountAd(uuid: string) {
    const url = Endpoints.MARKETING.ACCOUNTS_AD_PUT(uuid);
    const opt = this.sessionService.getOptions();

    return this.http.delete(url, opt);
  }
  putAccountsAd(uuid: string, body: any) {
    const url = Endpoints.MARKETING.ACCOUNTS_AD_PUT(uuid);
    const opt = this.sessionService.getOptions();

    return this.http.put(url, body, opt);
  }

  getDashboardEvents() {
    const url = Endpoints.DASHBOARD.EVENTS;
    const opt = this.sessionService.getOptions();

    return this.http.get(url, opt);
  }

  getDashboardFinances() {
    const url = Endpoints.DASHBOARD.FINANCES;
    const opt = this.sessionService.getOptions();

    return this.http.get(url, opt);
  }

  getDashboardOpportunities() {
    const url = Endpoints.DASHBOARD.OPPORTUNITIES;
    const opt = this.sessionService.getOptions();

    return this.http.get(url, opt);
  }
  getDashboardOpportunitiesPorcentagem() {
    const url = Endpoints.DASHBOARD.OPPORTUNITIES_PORCENTAGEM;
    const opt = this.sessionService.getOptions();

    return this.http.get(url, opt);
  }
}
