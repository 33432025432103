import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-table-order',
    templateUrl: './table-order.component.html',
})
export class TableOrderComponent   {

    @Input()
    columnName;

    @Input()
    order;

    @Input()
    type;

    @Output()
    onOrder = new EventEmitter();
    constructor(

    ) { }

    click(){
        const type = (this.type == 'desc') ? 'asc': 'desc';
        this.onOrder.emit({order: this.columnName, type});
    }
}