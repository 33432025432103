import {NgModule} from '@angular/core';
import {AvatarComponent} from './avatar.component'


@NgModule({
    imports: [
    ],
    declarations: [
        AvatarComponent
    ],
    exports: [
        AvatarComponent
    ]
})
export class AvatarModule { }